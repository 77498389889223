import React from 'react';

export default function PageFooter() {
  return (
    <div id="footer">
      <ul className="copyright">
        <li>&copy; 2021 SP</li>
      </ul>
    </div>
  );
}
